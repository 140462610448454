/* CSS reset */
@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap&family=Hammersmith+One&family=Londrina+Solid:wght@900&display=swap');

*,
*::after,
*::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}


.bg-planet-svg {
    position: absolute;
    top: 250px;
    right: -205px;
    z-index: -5;
    width: 450px;
}

.bg-planet2-svg {
    position: absolute;
    bottom: -125px;
    left: -180px;
    z-index: -5;
    width: 450px;
    opacity: 0.8;
}

.bg-planet3-svg {
    position: absolute;
    top: -65px;
    left: -170px;
    z-index: -5;
    width: 450px;
    opacity: 0.8;
}

/* html {
    font-size: 62.5%;
} */

body {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    /* position: relative; */
}

/* Typography =======================*/

/* Headings */

/* Main heading for card's front cover */
.card-front__heading {
    font-size: 1.5rem;
    margin-top: .25rem;
}

/* Main heading for inside page */
.inside-page__heading {
    padding-bottom: 1rem;
    width: 100%;
}

/* Mixed */

/* For both inside page's main heading and 'view me' text on card front cover */
.inside-page__heading,
.card-front__text-view {
    font-size: 1.3rem;
    font-weight: 800;
    margin-top: .2rem;
}

.inside-page__heading--city,
.card-front__text-view--city {
    color: #ff62b2;
}

.inside-page__heading--ski,
.card-front__text-view--ski {
    color: #2aaac1;
}

.inside-page__heading--beach,
.card-front__text-view--beach {
    color: #fa7f67;
}

.inside-page__heading--camping,
.card-front__text-view--camping {
    color: #00b97c;
}

/* Front cover */

.card-front__tp {
    color: #fafbfa;
}

/* For pricing text on card front cover */
.card-front__text-price {
    font-size: 1.2rem;
    margin-top: -.2rem;
}

/* Back cover */

/* For inside page's body text */
.inside-page__text {
    color: #333;
}

/* Icons ===========================================*/

.card-front__icon {
    fill: #fafbfa;
    font-size: 3vw;
    height: 3.25rem;
    margin-top: -.5rem;
    width: 3.25rem;
}

/* Buttons =================================================*/


.inside-page__btn {
    background-color: transparent;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0rem;
    overflow: hidden;
    padding: 1.7rem 0.75rem;
    position: relative;
    text-decoration: none;
    transition: all .3s ease;
    width: 90%;
    z-index: 10;
}

.inside-page__btn::before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scaleY(0);
    transition: all .3s ease;
    width: 100%;
    z-index: -1;
}

.inside-page__btn--city {
    border-color: #ff40a1;
    color: #ff40a1;
}

.inside-page__btn--city::before {
    background-color: #ff40a1;
}

.inside-page__btn--ski {
    border-color: #279eb2;
    color: #279eb2;
}

.inside-page__btn--ski::before {
    background-color: #279eb2;
}

.inside-page__btn--beach {
    border-color: #fa7f67;
    color: #fa7f67;
}

.inside-page__btn--beach::before {
    background-color: #fa7f67;
}

.inside-page__btn--camping {
    border-color: #00b97d;
    color: #00b97d;
}

.inside-page__btn--camping::before {
    background-color: #00b97d;
}

.inside-page__btn:hover {
    color: #fafbfa;
}

.inside-page__btn:hover::before {
    transform: scaleY(1);
}

/* Layout Structure=========================================*/

.main {
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    height: 100vh;
    /* width: 100%; */
}

/* Container to hold all cards in one place */
.card-area {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    height: 50%;
    justify-content: space-evenly;
    padding: 1rem;
}

/* Card ============================================*/

/* Area to hold an individual card */
.card-section {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

/* A container to hold the flip card and the inside page */
.card {
    background-color: rgba(0, 0, 0, .05);
    box-shadow: -.1rem 1.7rem 6.6rem -3.2rem rgba(0, 0, 0, 0.5);
    height: 12rem;
    position: relative;
    transition: all 1s ease;
    width: 12rem;
}

/* Flip card - covering both the front and inside front page */

/* An outer container to hold the flip card. This excludes the inside page */
.flip-card {
    height: 15rem;
    perspective: 100rem;
    position: absolute;
    right: 0;
    transition: all 1s ease;
    visibility: hidden;
    width: 15rem;
    z-index: 100;
}

/* The outer container's visibility is set to hidden. This is to make everything within the container NOT set to hidden  */
/* This is done so content in the inside page can be selected */
.flip-card>* {
    visibility: visible;
}

/* An inner container to hold the flip card. This excludes the inside page */
.flip-card__container {
    height: 100%;
    position: absolute;
    right: 0;
    transform-origin: left;
    transform-style: preserve-3d;
    transition: all 1s ease;
    width: 100%;
}

.card-front,
.card-back {
    backface-visibility: hidden;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

/* Styling for the front side of the flip card */

/* container for the front side */
.card-front {
    background-color: #fafbfa;
    height: 15rem;
    width: 15rem;
}

/* Front side's top section */
.card-front__tp {
    align-items: center;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
    display: flex;
    flex-direction: column;
    height: 12rem;
    justify-content: center;
    padding: .75rem;
}

.card-front__tp--city {
    background: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWeNyc03Y3WPIIAXtViG2FPJ-W4d9Kue70LQ&usqp=CAU'),

}

.card-front__tp--ski {
    background: linear-gradient(to bottom,
            #47c2d7,
            #279eb2);
}

.card-front__tp--beach {
    background: linear-gradient(to bottom,
            #fb9b88,
            #f86647);
}

.card-front__tp--camping {
    background: linear-gradient(to bottom,
            #00db93,
            #00b97d);
}

/* Front card's bottom section */
.card-front__bt {
    align-items: center;
    display: flex;
    justify-content: center;
}

/* Styling for the back side of the flip card */

.card-back {
    background-color: #fafbfa;
    transform: rotateY(180deg);
}

/* Specifically targeting the <video> element */
.video__container {
    clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
    height: auto;
    min-height: 100%;
    object-fit: cover;
    width: 100%;
}

/* Inside page */

.inside-page {
    background-color: #fafbfa;
    box-shadow: inset 20rem 0px 5rem -2.5rem rgba(0, 0, 0, 0.25);
    height: 100%;
    padding: 1rem;
    position: absolute;
    right: 0;
    transition: all 1s ease;
    width: 15rem;
    z-index: 1;
}

.inside-page__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    width: 100%;
}

/* Functionality ====================================*/

/* This is to keep the card centered (within its container) when opened */
.card:hover {
    box-shadow:
        -.1rem 1.7rem 6.6rem -3.2rem rgba(0, 0, 0, 0.75);
    width: 30rem;
}

/* When the card is hovered, the flip card container will rotate */
.card:hover .flip-card__container {
    transform: rotateY(-180deg);
}

/* When the card is hovered, the shadow on the inside page will shrink to the left */
.card:hover .inside-page {
    box-shadow: inset 1rem 0px 5rem -2.5rem rgba(0, 0, 0, 0.1);
}

/* Footer ====================================*/

.footer {
    background-color: #333;
    margin-top: 3rem;
    padding: 1rem 0;
    width: 100%;
}

.footer-text {
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
}